import { ORDERS } from "../constants/actions";

const INIT_STATE = null;

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ORDERS:
      return action.payload;
    default:
      return state;
  }
};
