import { combineReducers } from "redux";

import authReducer from "./auth";
import menuReducer from "./nav";
import agencyReducer from "./agency";
import ordersReducer from "./orders";
import carsReducer from "./cars";
import inboxReducer from "./inbox";
import shortcutReducer from "./shortcut";
import reservationReducer from "./reservation";


const allReducers = combineReducers({
  authreducer: authReducer,
  ordersreducer: ordersReducer,
  menu: menuReducer,
  agencyreducer: agencyReducer,
  carsreducer: carsReducer,
  inboxreducer: inboxReducer,
  shortcutreducer: shortcutReducer,
  reservationreducer: reservationReducer,

});

export default allReducers;
